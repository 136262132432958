html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.navbar {
	height: 80px;
	background: linear-gradient(to right, #f77063, #ff5296);
	border: none;
	color: white;
	z-index: 100;
	transition: background-color 1s ease 0s;
}
.navbar-default.navbar-brand {
	margin-top: 10px;
	padding: 15px;
}

a.nav-link {
	color: white;
	font-size: 20px;
}

.navIcons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

img.navIcons {
	width: 40px;
	margin: 15px;
	transition: 0.3s;
}

a {
	text-decoration: none;
}

img.navIcons:hover {
	transition: 0.3s ease;
	transform: scale(1.2, 1.2);
}

video {
	position: relative;
	right: 0;
	bottom: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	opacity: 1;
}

.shadedColor {
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
}
div.headerText {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 30%;
}

.headerText h1 {
	color: white;
}
.projects-title {
	display: flex;
	justify-content: center;
	margin: 25px 0;
}

.projects-text-muted {
	color: white;
}
.full-stack {
	font-weight: bold;
	font-size: 20px;
}

.my-name {
	font-weight: bold;
}

.img-top {
	height: 100vh;
	width: 100%;
	overflow: hidden;
}
.header-video {
	opacity: 0.5;
	width: 100%;
	overflow: hidden;
}
.this {
	text-decoration: none;
	margin: 0 15px;
	color: white;
	background-color: #f77063;
	font-family: sans-serif;
	font-size: 20px;
	border: 0.5px solid black;
	padding: 1px 15px;
	position: relative;
	overflow: hidden;
	transition: 1s;
}

.this:hover {
	color: white;
	cursor: pointer;
}

.headerText p {
	color: white;
}

.profileImg {
	width: 25em;
	border-radius: 360px;
}
.bodyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.aboutContainer {
	margin-top: 15px;
	margin-right: 25px;
	padding: 15px;
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 3px 15px #e1e9f1;
	border-radius: 10px;
}

.RowCenterSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 25px 0;
}

.skills {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 auto;
	width: 80%;
	transition: all 0.3s;
}

.skillsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 35px 35px 45px;
	position: relative;
	width: 100%;
}

.htmlLogo {
	margin: 15px;
	height: 7em;
}
.logos {
	margin: 15px;
	height: 7em;
}

.skillsList {
	padding: 40px;
}
.additionalSkillsContainer {
	display: flex;
	justify-content: center;
}

.AdditionalSkillsHeader {
	display: flex;
	justify-content: center;
}
.projectsContainer {
	background: #f77063;
	background: linear-gradient(to right bottom, #f77063 55%, #ffb6af 45%);
	width: 100%;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 80px;
}

.project-title {
	display: flex;
	width: 100%;
	margin: 0 auto;
	justify-content: center;
	top: 37px;
	position: absolute;
	color: black;
	font-size: 25px;
	font-weight: bold;
}

.flexStart {
	/* display: flex;
  flex-direction: column;
  align-items: flex-start; */
}
.project-description {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	color: black;
}
.project-slide {
	position: relative;
	padding: 100px 0;
	background-color: white;
	border-radius: 5px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.carousel-item {
	transition: 1s ease-in;
}
.projectsContainer h1 {
	color: white;
}

.projects-title {
	margin-top: 4%;
}
.projectCards {
	width: 70%;
	margin: 3% auto;
	display: flex;
	flex-wrap: wrap;
}
img.card-img-top * {
	box-sizing: border-box;
}

.contactButton {
	background-color: #f77063;
	border: #f77063;
	border-radius: 50%;
	height: 71px;
}

.connectAnchor,
.connectAnchor:hover {
	text-decoration: none;
	color: white;
}

.contactButton:hover {
	background-color: #ff5296;
	border: #ff5296;
	transition: all 1s ease 0s;
}

.button-container {
	display: flex;
	justify-content: center;
}

.SkillsHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
}
.hiddenSkills {
	position: absolute;
	opacity: 0;
	transition: all 1s ease;
}

.transitionHeaderSkill {
	position: absolute;
	opacity: 0;
	transition: all 1s ease;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.showSkills {
	transition: 1s ease;
	opacity: 1;
	position: relative;
}

.skillsButton {
	display: block;
	background: #f77063;
	color: white;
	border: none;
	position: relative;
	height: 60px;
	font-size: 1.6em;
	padding: 0 2em;
	cursor: pointer;
	transition: 800ms ease all;
	outline: none;
}
.skillsButton:hover {
	background: #fff;
	color: #f77063;
}
.skillsButton:focus {
	outline: none;
	background: #ffb4ad;
	color: white;
}
.skillsButton:before,
.skillsButton:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	height: 2px;
	width: 0;
	background: #f77063;
	transition: 400ms ease all;
}
.skillsButton:after {
	right: inherit;
	top: inherit;
	left: 0;
	bottom: 0;
}
.skillsButton:hover:before,
.skillsButton:hover:after {
	width: 100%;
	transition: 800ms ease all;
}

.emailContainer {
	display: flex;
	justify-content: center;
}

.phoneContainer {
	display: flex;
	justify-content: flex-start;
	margin: 25px 0 8px 0;
}
.phone,
.email {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.fa-envelope,
.fa-phone {
	border: solid 1px gray;
	padding: 7px;
	border-radius: 80%;
	font-size: 1.2em;
	margin: 0 15px 0 0;
}

.pojectCards .card {
	margin: 35px 0;
}

.footerContainer {
	display: flex;
	justify-content: space-around;
}

.phoneEmailSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-right: 1.4px solid #d6d6d6;
}

div.socialMediaLinks {
	width: 100%;
	padding: 15px;
	display: flex;
	justify-content: center;
}

.socialIconLinks {
	display: flex;
	flex-direction: column;
	align-items: center;
}

div.socialSection {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.socialIconFooter {
	display: flex;
	justify-content: center;
}
.linkedinAnchorFooter,
.githubText {
	color: black;
}
.linkedinAnchorFooter:hover {
	color: black;
}

@media only screen and (max-width: 1390px) {
	.img-top {
		height: 100%;
		width: 100%;
	}
	div.headerText {
		position: absolute;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		top: 20%;
	}
	.profileImg {
		width: 15em;
		border-radius: 150px;
	}
	div.headerText {
		position: absolute;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		top: 30%;
	}
}

@media only screen and (max-width: 1170px) {
	div.headerText {
		position: absolute;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		top: 20%;
	}
}

@media only screen and (max-width: 991px) {
	div.headerText {
		top: 15%;
	}

	.navbar-collapse {
		width: 100%;
		background-color: white;
		border-radius: 1%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	a.nav-link,
	.navbar-nav {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.RowCenterSection {
		flex-direction: column-reverse;
		justify-content: space-around;
	}
}

@media only screen and (max-width: 765px) {
	div.headerText {
		top: 17%;
		position: absolute;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.profileImg {
		width: 10em;
		border-radius: 150px;
	}
}

@media only screen and (max-width: 621px) {
	div.headerText {
		top: 12.3%;
		position: absolute;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.skillsButton {
		font-size: 1.2em;
	}
	.profileImg {
		width: 10em;
		border-radius: 150px;
	}
	.footerContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.phoneEmailSection {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		border-bottom: 1.4px solid #d6d6d6;
		border-right: none;
	}
	.footerIconContainer {
		display: flex;
		flex-direction: column;
		align-self: flex-start;
		margin: 0 auto;
	}
	.emailContainer {
		padding-bottom: 15px;
	}
	.projectCards {
		width: 100%;
		margin: 3% auto;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
}

/* Theme Style */
html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}
.theme-professional {
	--color-primary: #0060df;
	--color-secondary: #fbfbfe;
	--color-accent: #fd6f53;
	--font-color: #000000;
}
.bat-theme {
	--color-primary: #17ed90;
	--color-secondary: #2a2c2d;
	--color-accent: #12cdea;
	--font-color: #ffffff;
}
.container {
	display: flex;
	width: 100%;
	height: 100%;
	background: var(--color-secondary);
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.container h1 {
	color: var(--font-color);
	font-family: sans-serif;
}
.container button {
	color: var(--font-color);
	background: var(--color-primary);
	padding: 10px 20px;
	border: 0;
	border-radius: 5px;
}

/* Creative mode button */

.btn {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	width: 200px;
}

.creativeBtn {
	border: none;
	font-family: Bangers;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	width: 200px;
}

.btn:after {
	content: "";
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	width: 200px;
}

.creativeBtn:after {
	content: "";
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	width: 200px;
}

.btn-5 {
	color: black;
	height: 70px;
	min-width: 220px;
	width: 200px;
	line-height: 24px;
	font-size: 16px;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.btn-5:active {
	background: #9053a9;
	top: 2px;
}

.btn-5 span {
	width: 200px;
	height: 100%;
	-webkit-transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-transition: all 0.3s;
	-moz-backface-visibility: hidden;
	transition: all 0.3s;
	backface-visibility: hidden;
}

.btn-5:before {
	position: absolute;
	height: 100%;
	width: 200px;
	line-height: 2.5;
	font-size: 180%;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn-5:active:before {
	color: #703b87;
	width: 200px;
}

/* Button 5a */
.btn-5a:hover span {
	-webkit-transform: translateY(300%);
	-moz-transform: translateY(300%);
	-ms-transform: translateY(300%);
	transform: translateY(300%);
}

.btn-5a:before {
	left: 0;
	top: -100%;
}

.btn-5a:hover:before {
	top: 0;
}

.icon-cog:before {
	content: url("../../icons/BatHead.png");
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.btnText {
	width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.25rem;
	border: solid;
}

.creativeText {
	width: 200px;
	display: flex;
	font-family: Bangers;
	justify-content: center;
	align-items: center;
	border-radius: 0.25rem;
	border: solid;
}
