html,
.App,
#root {
	width: 100%;
	height: 100%;
}

body {
	margin: 0;
	width: 100%;
	height: 100%;
}

/* Loading screen dots animation */
.spinner {
	/* margin: 100px auto 0; */
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: white;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

/* React-bootstrap carousel styles */
.carousel-control-next,
.carousel-control-prev {
	z-index: 0;
}

.carousel-indicators {
	margin: 0;
}

.carousel-indicators li {
	visibility: hidden;
}

a.carousel-control-next,
a.carousel-control-prev {
	background-color: black;
	height: 5%;
	top: 40%;
}

/* Hamburger navigation hover effect to navigate sections */
.hamburger {
	width: 3em;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
}

.thisNav {
	z-index: 10;
	position: fixed;
	background-color: #e2e2e2;
	border-radius: 50px;
	padding: 7px;
	display: flex;
	justify-content: flex-end;
	-webkit-box-pack: end;
	align-items: center;
	transition: 0.8s ease;
	width: 4em;
	height: 65px;
	overflow: hidden;
	right: 14px;
	bottom: 13px;
	border: 0.8px solid black;
	box-shadow: black 8px 8px 13px;
}

.thisNav:hover {
	width: 500px;
	z-index: 60;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: end;
	justify-content: flex-end;
	box-shadow: rgba(0, 0, 0, 0.2) -5px -5px 10px;
	position: fixed;
	right: 0px;
	bottom: 0px;
	background: rgba(255, 255, 255, 0.98);
	padding: 0px 45px 0px 0px;
	border-radius: 0px;
	border-top-left-radius: 20px;
	opacity: 1;
	transform: translateX(92px);
	border: 0 solid black;
}
